import React from 'react';

const Logo = () => {
  return (
    <svg className="c-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220.816 52.704">
      <g transform="translate(-212.184 -238.448)">
        <path fill="#ea4335" d="M37.44-8.5a19.766,19.766,0,0,1-6.588,6.984A18.309,18.309,0,0,1,20.736,1.152a19.434,19.434,0,0,1-7.452-1.4,17.932,17.932,0,0,1-5.94-3.924,17.932,17.932,0,0,1-3.924-5.94,19.628,19.628,0,0,1-1.4-7.524,19.3,19.3,0,0,1,1.368-7.236A18.448,18.448,0,0,1,7.2-30.852,18.449,18.449,0,0,1,13-34.92a17.724,17.724,0,0,1,7.38-1.512,19.216,19.216,0,0,1,7.56,1.4A15.422,15.422,0,0,1,33.48-31.14a16.731,16.731,0,0,1,3.384,5.868,22.651,22.651,0,0,1,1.152,7.344v.864a4.848,4.848,0,0,0-.072.792,4.046,4.046,0,0,0-.072.792H11.3a9.922,9.922,0,0,0,1.188,3.708A8.995,8.995,0,0,0,14.724-9.18,8.677,8.677,0,0,0,17.64-7.668a11.376,11.376,0,0,0,3.24.468,9.184,9.184,0,0,0,5.364-1.476,10.771,10.771,0,0,0,3.348-3.708Zm-8.568-13.68a6.107,6.107,0,0,0-.612-2.088,7.351,7.351,0,0,0-1.584-2.16,8.291,8.291,0,0,0-2.592-1.656,9.58,9.58,0,0,0-3.708-.648A8.639,8.639,0,0,0,15.048-27a9.123,9.123,0,0,0-3.24,4.824Z" transform="translate(394.984 290)" />
        <path fill="#34a853" d="M4.032,0V-51.552h9.432V0Z" transform="translate(377.968 290)" />
        <path fill="#4285f4" d="M4.032-51.552h9.432v15.48l-.576,5.04h.576A10.7,10.7,0,0,1,17.5-34.848a13.322,13.322,0,0,1,6.768-1.584,15.275,15.275,0,0,1,6.444,1.4,17.229,17.229,0,0,1,5.4,3.888,18.465,18.465,0,0,1,3.708,5.94,20.363,20.363,0,0,1,1.368,7.56,20.363,20.363,0,0,1-1.368,7.56,18.465,18.465,0,0,1-3.708,5.94,17.229,17.229,0,0,1-5.4,3.888,15.275,15.275,0,0,1-6.444,1.4A13.322,13.322,0,0,1,17.5-.432a10.7,10.7,0,0,1-4.032-3.816h-.576V0H4.032ZM22.32-7.56a9.009,9.009,0,0,0,3.564-.72A9.381,9.381,0,0,0,28.872-10.3a9.841,9.841,0,0,0,2.088-3.168,10.617,10.617,0,0,0,.792-4.176,10.617,10.617,0,0,0-.792-4.176,9.841,9.841,0,0,0-2.088-3.168A9.381,9.381,0,0,0,25.884-27a9.009,9.009,0,0,0-3.564-.72,9.419,9.419,0,0,0-3.564.684,8.9,8.9,0,0,0-2.988,1.98,9.841,9.841,0,0,0-2.088,3.168,10.811,10.811,0,0,0-.792,4.248,10.811,10.811,0,0,0,.792,4.248,9.841,9.841,0,0,0,2.088,3.168,8.9,8.9,0,0,0,2.988,1.98A9.419,9.419,0,0,0,22.32-7.56Z" transform="translate(334.968 290)" />
        <path fill="#fbbc05" d="M20.736-36.432a19.628,19.628,0,0,1,7.524,1.4,17.81,17.81,0,0,1,5.9,3.888,17.725,17.725,0,0,1,3.888,5.94,19.9,19.9,0,0,1,1.4,7.56,19.9,19.9,0,0,1-1.4,7.56,17.725,17.725,0,0,1-3.888,5.94,17.811,17.811,0,0,1-5.9,3.888,19.628,19.628,0,0,1-7.524,1.4,19.628,19.628,0,0,1-7.524-1.4,17.811,17.811,0,0,1-5.9-3.888A17.725,17.725,0,0,1,3.42-10.08a19.9,19.9,0,0,1-1.4-7.56,19.9,19.9,0,0,1,1.4-7.56,17.725,17.725,0,0,1,3.888-5.94,17.81,17.81,0,0,1,5.9-3.888A19.628,19.628,0,0,1,20.736-36.432Zm0,28.872a9.235,9.235,0,0,0,3.492-.684,8.9,8.9,0,0,0,2.988-1.98,9.385,9.385,0,0,0,2.052-3.168,11.264,11.264,0,0,0,.756-4.248,11.264,11.264,0,0,0-.756-4.248,9.385,9.385,0,0,0-2.052-3.168,8.9,8.9,0,0,0-2.988-1.98,9.235,9.235,0,0,0-3.492-.684,9.149,9.149,0,0,0-3.528.684,9,9,0,0,0-2.952,1.98A9.385,9.385,0,0,0,12.2-21.888a11.264,11.264,0,0,0-.756,4.248,11.264,11.264,0,0,0,.756,4.248,9.385,9.385,0,0,0,2.052,3.168,9,9,0,0,0,2.952,1.98A9.149,9.149,0,0,0,20.736-7.56Z" transform="translate(293.984 290)" />
        <path fill="#ea4335" d="M20.736-36.432a19.628,19.628,0,0,1,7.524,1.4,17.81,17.81,0,0,1,5.9,3.888,17.725,17.725,0,0,1,3.888,5.94,19.9,19.9,0,0,1,1.4,7.56,19.9,19.9,0,0,1-1.4,7.56,17.725,17.725,0,0,1-3.888,5.94,17.811,17.811,0,0,1-5.9,3.888,19.628,19.628,0,0,1-7.524,1.4,19.628,19.628,0,0,1-7.524-1.4,17.811,17.811,0,0,1-5.9-3.888A17.725,17.725,0,0,1,3.42-10.08a19.9,19.9,0,0,1-1.4-7.56,19.9,19.9,0,0,1,1.4-7.56,17.725,17.725,0,0,1,3.888-5.94,17.81,17.81,0,0,1,5.9-3.888A19.628,19.628,0,0,1,20.736-36.432Zm0,28.872a9.235,9.235,0,0,0,3.492-.684,8.9,8.9,0,0,0,2.988-1.98,9.385,9.385,0,0,0,2.052-3.168,11.264,11.264,0,0,0,.756-4.248,11.264,11.264,0,0,0-.756-4.248,9.385,9.385,0,0,0-2.052-3.168,8.9,8.9,0,0,0-2.988-1.98,9.235,9.235,0,0,0-3.492-.684,9.149,9.149,0,0,0-3.528.684,9,9,0,0,0-2.952,1.98A9.385,9.385,0,0,0,12.2-21.888a11.264,11.264,0,0,0-.756,4.248,11.264,11.264,0,0,0,.756,4.248,9.385,9.385,0,0,0,2.052,3.168,9,9,0,0,0,2.952,1.98A9.149,9.149,0,0,0,20.736-7.56Z" transform="translate(251.984 290)" />
        <path fill="#4285f4" d="M5.184-51.552h20.16a18.033,18.033,0,0,1,6.192,1.044,15.8,15.8,0,0,1,5,2.88,13.746,13.746,0,0,1,3.348,4.284,11.591,11.591,0,0,1,1.224,5.256,11.527,11.527,0,0,1-1.98,6.876A13,13,0,0,1,34.2-27v.576a14,14,0,0,1,6.372,4.5,11.891,11.891,0,0,1,2.412,7.6,12.953,12.953,0,0,1-1.332,5.94,13.747,13.747,0,0,1-3.636,4.5,16.7,16.7,0,0,1-5.364,2.88A20.933,20.933,0,0,1,26.064,0H5.184Zm19.44,21.024a7.061,7.061,0,0,0,5.22-1.836A5.952,5.952,0,0,0,31.68-36.72a5.892,5.892,0,0,0-1.8-4.32,6.714,6.714,0,0,0-4.968-1.8H14.9v12.312Zm1.08,21.6a7.876,7.876,0,0,0,5.616-1.836,6.2,6.2,0,0,0,1.944-4.716,6.372,6.372,0,0,0-1.98-4.788q-1.98-1.908-5.868-1.908H14.9V-8.928Z" transform="translate(207 290)" />
      </g>
    </svg>
  );
};

export default Logo;
